import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section className="section section--gradient content-page">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
                Sidan kunde inte hittas
              </h1>
              <p>Vi kunde tyvärr inte hitta sidan du sökte.</p>
              <p>Gå till <Link to="/">startsidan</Link> eller navigera till en annan sida via länkarna i menyn.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
